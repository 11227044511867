import { initializeApp } from "firebase/app";
import { getFirestore } from '@firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyAHteiAMtHB0F3SfViFacz5pwPB9Z61NJY",
  authDomain: "avanom-site-two-75589.firebaseapp.com",
  projectId: "avanom-site-two-75589",
  storageBucket: "avanom-site-two-75589.appspot.com",
  messagingSenderId: "318603163661",
  appId: "1:318603163661:web:479ca88131adf621ca7d44"
  };

const app = initializeApp(firebaseConfig);


export const db = getFirestore(app);