import React from 'react'
import { Link } from "react-router-dom";
import ham_icon from '../../res/Repeat Grid 1.png';
import ham_icon_two from '../../res/Repeat Grid 2.png';
import logo from '../../res/logo.png';
import './navbar.css';


function Navbar() {

    let toggle = true;
    
    const click=()=>{
        var img = document.getElementById("ham");

        toggle = !toggle;
        if (!toggle) {
            img.src = ham_icon_two;
            document.getElementById("nabar_holer").style.padding = "0% 0% 10% 0%";

        }
        else{
            img.src = ham_icon;
            document.getElementById("nabar_holer").style.padding = "0% 0% 0% 0%";
        }

    }
    return (
        <div id='nabar_holer' className="nabar_holer">
            <div className="navbar_mobile">
                <div className="navbar_mobile_logo">
                    <Link to="/" ><img src={logo} alt="logo" /></Link>
                </div>
                <div className="navbar_mobile_ham">
                    <img id='ham' onClick={click} src={ham_icon} alt="ham_icon" />
                </div>
            </div>
            <div className="navbar_content_holder">
                <div className="image_container">
                    <Link to="/"><img src={logo} alt="logo" /></Link>
                </div>
                <div className="text_contain_nav">
                    <Link to="/">Home</Link>
                    <Link to="about_us">Who We Are</Link>
                    <Link to='product/All%20Products'>Products</Link>
                    <Link to='contact_us'>Contact Us</Link>
                </div>
                {/* <div className="navbar_list">
            </div> */}
            </div>
        </div>
    )
}

export default Navbar;